<template>
    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs>
        <v-row :style="`margin:auto;width:80%;direction:`+ldir+`;text-align:right`">
            <v-col cols="12" :style="`direction:`+ldir+`;text-align:`+lalgin+`;`">
                <div class="backGold" style="padding:0px;width:100%;text-align:center;">
                    <b-button class="mytab1 btn btn-sm btn-success m-2" style="width:120px;" @click="printPDF()">{{lang.printQuot}}</b-button> 
                    <b-button class="mytab1 btn btn-sm btn-success m-2" style="width:140px;" @click="printQuot()">{{lang.view_pdf}}</b-button> 
                    <b-button style="background:darkblue;width:140px;" v-if="quot.satuts == 1" class="mytab2 btn btn-sm btn-primary m-2" @click="invQuot()" v-b-toggle.add_qinvoice>{{lang.invoice}}</b-button> 
                    <b-button style="background:darkred;width:120px;" class="mytab2 btn btn-sm btn-danger m-2" v-if="quot.satuts == 1" @click="returnNow(0)" v-b-toggle.returnInvoice>{{lang.cancel_quto}}</b-button> 
                </div>
            </v-col>
            <b-table-simple class="m-2">
                <tbody>
                    <tr>
                        <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.quotnumber}}</th>
                        <td style="border:1px solid #555;text-align:center;">{{ quot.quot_number }}</td>
                        <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.quot_date}}</th>
                        <td style="border:1px solid #555;text-align:center;">{{ quot._quot_date }}</td>
                        <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.quot_time}}</th>
                        <td style="border:1px solid #555;text-align:center;">{{ quot.quot_time }}</td>
                        <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.status}}</th>
                        <td style="border:1px solid #555;text-align:center;">{{ quot._status }}</td>
                    </tr>
                    <tr>
                        <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.mobile}}</th>
                        <td style="border:1px solid #555;text-align:center;">{{ quot.mobile }}</td>
                        <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.customer_name}}</th>
                        <td style="border:1px solid #555;text-align:center;">{{ quot.full_name }}</td>
                        <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.company_name}}</th>
                        <td style="border:1px solid #555;text-align:center;">{{ quot.company_name }}</td>
                        <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.salesman}}</th>
                        <td style="border:1px solid #555;text-align:center;">{{ quot.agentid }}</td>
                    </tr>
                </tbody>
            </b-table-simple>
        </v-row>
        <v-row style="margin:auto;width:80%">
            <h6>{{lang.quotation_details}}</h6>
            <b-table-simple class="m-2">
                <thead>
                    <tr>
                        <th style="text-align:center;">{{lang.item_code}}</th>
                        <th style="text-align:center;">{{lang.description}}</th>
                        <th style="text-align:center;">{{lang.qtty}}</th>
                        <th style="text-align:center;">{{lang.item_price}}</th>
                        <th style="text-align:center;">{{lang.total}}</th>
                        <th style="text-align:center;">{{lang.vat}} 15%</th>
                        <th style="text-align:center;">{{lang.ftotal}}</th>
                        <th style="text-align:center;">{{lang.action}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in quot.rows" :key="index">
                        <td style="text-align:center;">{{item.itemcode}}</td>
                        <td style="text-align:center;">{{item.item_name}}</td>
                        <td style="text-align:center;">{{item.qty}}</td>
                        <td style="text-align:center;">{{item.item_price}}</td>
                        <td style="text-align:center;">{{item.total}}</td>
                        <td style="text-align:center;">{{item.vat}}</td>
                        <td style="text-align:center;">{{item.ftotal}}</td>
                        <td style="text-align:center;">
                            <b-button class="btn-sm btn-danger">{{lang.delete}}</b-button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <v-text-field
                                v-model="newrow.itemcode"
                                @change="calcKit()"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-combobox
                                v-model="newrow.item_name"

                                small-chips
                            ></v-combobox>
                        </td>
                        <td>
                            <v-text-field
                                v-model="newrow.qty"
                                @change="calcKit()"
                                type="number"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-model="newrow.item_price"
                                @change="calcKit()"
                                type="number"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-model="newrow.total"
                                @change="calcKit()"
                                type="number"
                                readonly
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-model="newrow.vat"
                                @change="calcKit()"
                                type="number"
                                readonly
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-model="newrow.ftotal"
                                @change="calcKit()"
                                type="number"
                                readonly
                            ></v-text-field>
                        </td>
                        <td>
                            <b-button type="button" variant="success" class="btn-sm" @click="addNewRow()">{{lang.add}}</b-button>
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
            <b-table-simple :style="`width:400px;margin-`+lang.algin+`:-8px;margin-`+lang.lalgin+`:auto;`">
                <thead>
                    <tr>
                        <th :class="`text-`+lang.lalgin+``">{{lang.total_exclusive_vat}}</th>
                        <td style="width:25%;border:1px solid #ccc;text-align:center;">{{quot.total}}</td>
                    </tr>
                    <tr>
                        <th :class="`text-`+lang.lalgin+``">{{lang.vat}} 15%</th>
                        <td style="width:25%;border:1px solid #ccc;text-align:center;">{{quot.vat}}</td>
                    </tr>
                    <tr>
                        <th :class="`text-`+lang.lalgin+``">{{lang.total_include_vat}}</th>
                        <td style="width:25%;border:1px solid #ccc;text-align:center;">{{quot.ftotal}}</td>
                    </tr>
                </thead>
            </b-table-simple>
        </v-row>
        <add-invoice-quot ref="invoiceQuot" v-if="showme" :quots='quot' />
    </div>
</template>

<script>
import BreadCrumbs from '@/components/breadCrumbs.vue'
import axios from 'axios'
import AddInvoiceQuot from '@/components/addInvoiceQuot.vue'

export default{
    components: {BreadCrumbs,
        AddInvoiceQuot,
    },
    data() {
        return {
            showme: false,
            
            quot: {},
            newrow:{
                itemcode: '',
                item_name: '',
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            },
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                text: this.lang.quotations,
                disabled: true,
                href: '/view-quot',
            }
        }
    },
    methods: {
        
        printQuot(){
            window.open('../api/print.php?quotid='+this.$route.params.id,'_blank');
        },
        getQuotations() {
            
            const post = new FormData();
            post.append('type', "getQuotations");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[id]',this.$route.params.id)
            post.append('data[status]',0)

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.quot = res.results.data[0];
            })

        },
        printPDF ()
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?quotid='+this.$route.params.id;
        }
    },
    created() {
        this.getQuotations();
    },
}
</script>